import {ReactElement, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { UsersApi } from "@devour/client";
import {toggleChatOpen} from "../../redux/meta/metaActions";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";

declare global {
		interface Window {
				Atlas: any;
		}
}

function AtlasChatWidget(): ReactElement {
		const dispatch = useDispatch();
		const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
		const isChatOpen = useSelector((store: IStore) => store.metaStore.isChatOpen);
		const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);

		useEffect(() => {
				const isAllowedUser: boolean = checkAllowedUser();
				if (
						(process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT.includes("staging")) &&
						isAllowedUser
				) {
						// If Env is prod or staging AND user is allowed (non dev team or not logged in), then init Atlas
						initAtlas();
				} else if (
						process.env.REACT_APP_ENVIRONMENT.includes("develop") ||
						process.env.REACT_APP_ENVIRONMENT.includes("local") ||
						!isAllowedUser
				) {
						// Disable Atlas for all users on develop and local, or for devs
						console.log("Atlas not initializing, env = ", process.env.REACT_APP_ENVIRONMENT);
				} else {
						// Should not get here, but just in case we should enable by default.
						console.log("Atlas initializing by default, env = ", process.env.REACT_APP_ENVIRONMENT);
						initAtlas();
				}
		}, []);

		useEffect(() => {
				if (isChatOpen) {
						openChatWindow();
				}
		}, [isChatOpen]);

		useEffect(() => {
				void identifyUser();
		}, [fullToken?.id]);

		function initAtlas() {
				if (!window.Atlas) {
						let t;
						const e = {
								appId: process.env.REACT_APP_ATLAS_ID,
								v: 2,
								q: [],
								call: function () {
										// eslint-disable-next-line react/no-this-in-sfc,prefer-rest-params
										this.q.push(arguments);
								},
						};
						window.Atlas = e;
						const n = document.createElement("script");
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						(n.async = !0), (n.src = "https://app.atlas.so/client-js/atlas.bundle.js");
						const s = document.getElementsByTagName("script")[0];
						(t = s.parentNode) === null || void 0 === t || t.insertBefore(n, s);
						window.Atlas.call("start", {
								chat: {
										hideBubble: true,
										onWindowVisibilityChange: (visible: boolean) => {
												if (!visible) {
														// Set the redux state back to false
														dispatch(toggleChatOpen(false));
												}
										},
								},
						});
				}
		}

		/**
		 * Returns true if the current user should be a user that Atlas should record for.
		 */
		function checkAllowedUser(): boolean {
				if (currentUser) {
						return !(
								currentUser.email === "cori.williams.913@gmail.com" ||
								currentUser.email === "mikepow91@gmail.com" ||
								currentUser.email.endsWith("@devour.io")
						);
				}
				return true;
		}

		async function identifyUser(): Promise<void> {
				if (fullToken && typeof window.Atlas?.call === "function") {
						const res = await new UsersApi(getConfig(fullToken)).getAtlasHash();
						window.Atlas.call(
								"identify",
								{
										userId: res.userId,
										name: res.name,
										email: res.email,
										userHash: res.userHash,
										customFields: {
												role: "Diner",
										},
								},
						);

				}
		}

		function openChatWindow(): void {
				if (typeof window.Atlas?.call === "function" && isChatOpen) {
						window.Atlas.call("triggerChatbot", isChatOpen);
				}
				if (typeof window.Atlas?.chat?.openWindow === "function") {
						window.Atlas.chat?.openWindow();
				}
		}

		return null;
}

export default AtlasChatWidget;
