import React, {ReactElement} from "react";
import {useDispatch} from "react-redux";
import {HiChatBubbleLeftRight} from "react-icons/hi2";
import {toggleChatOpen} from "../../redux/meta/metaActions";
import FrameButton from "../buttons/FrameButton";

function AtlasChatWidgetButton(): ReactElement {
    const dispatch = useDispatch();

    async function openChatWindow(): Promise<void> {
        dispatch(toggleChatOpen("devour_merchant_support"));
    }

    return (
        <div
            id="drift-widget-wrapper"
            className="drift-widget-wrapper"
        >
            <FrameButton
                color="purple"
                size="icon"
                leftIcon={HiChatBubbleLeftRight}
                onClick={openChatWindow}
            />
        </div>
    );
}

export default AtlasChatWidgetButton;
