import {MetaAction} from "./metaReducer";
import {ApiError, ErrorType, User} from "@devour/client";
import {IAction} from "../IAction";
import {isAPIError} from "../../utils/isAPIError";
import {IMetaStore} from "./InitialMetaStore";

export function incrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: -1,
	}
}

const makeDefaultError: () => ApiError = () => ({type: ErrorType.APP, message: "An unknown error has occurred. Please contact support or try your request again."});
export async function addError(error?: {json: () => Promise<ApiError>} | ApiError): Promise<IAction<MetaAction.ADD_ERROR, any>> {
	let _error: ApiError;

	try {
		if (isAPIError(error)) {
			_error = error;
		} else {
			try {
				const jsonResponse = await (error as {json: () => Promise<ApiError>}).json();
				if (isAPIError(jsonResponse)) {
					_error = jsonResponse
				} else {
					_error = makeDefaultError()
				}
			} catch (e) {
				_error = makeDefaultError();
			}
		}
	} catch (e) {
		_error = makeDefaultError();
	}

	return {
		type: MetaAction.ADD_ERROR,
		payload: _error,
	}
}

export function removeError(i: number = 0): IAction<MetaAction.REMOVE_ERROR, number> {
	return {
		type: MetaAction.REMOVE_ERROR,
		payload: i,
	}
}

export function toggleSidebar(open: boolean): IAction<MetaAction.SIDEBAR, boolean> {
	return {
		type: MetaAction.SIDEBAR,
		payload: open,
	}
}

export function toggleGlobalContactModalVisible(open: boolean): IAction<MetaAction.GLOBAL_CONTACT_MODAL, boolean> {
	return {
		type: MetaAction.GLOBAL_CONTACT_MODAL,
		payload: open,
	}
}

export function updateCurrentUser(user: User): IAction<MetaAction.UPDATE_CURRENT_USER, User> {
	return {
		type: MetaAction.UPDATE_CURRENT_USER,
		payload: user,
	}
}

export function incrementModalCount(): IAction<MetaAction.MODAL_COUNT, number> {
	return {
		type: MetaAction.MODAL_COUNT,
		payload: 1,
	}
}

export function decrementModalCount(): IAction<MetaAction.MODAL_COUNT, number> {
	return {
		type: MetaAction.MODAL_COUNT,
		payload: -1,
	}
}

export function toggleChatOpen(open: IMetaStore["isChatOpen"]): IAction<MetaAction.TOGGLE_CHAT_OPEN, IMetaStore["isChatOpen"]> {
		return {
				type: MetaAction.TOGGLE_CHAT_OPEN,
				payload: open,
		};
}
