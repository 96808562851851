import {ApiError, User} from "@devour/client";

export interface IMetaStore {
	loadingIncrement?: number;
	errors?: Array<ApiError>;
	sidebarOpen: boolean;
	globalContactModalVisible: boolean;
	currentUser: User;
	activeModals: number; // Helps manage nested modal render order.
	isChatOpen: false | "devour_merchant_support";
}

export default {
	loadingIncrement: 0,
	errors: [],
	sidebarOpen: false,
	globalContactModalVisible: false,
	currentUser: undefined,
	activeModals: 0,
	isChatOpen: false,
} as IMetaStore;
